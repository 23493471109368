import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { useTranslation } from "react-i18next"
import { getItemLocalStorage, addSpaceNumberRegEx } from "../../scripts/Utils"

import DeleteIcon from "../../svg/delete-icon.inline.svg"
import CompareIcon from "../../svg/location-icon.inline.svg"
import axios from "../../http/axios"

const UserProfileCompareSchoolsSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const [allItemLocalstorage, setAllItemLocalstorage] = useState([])
  const [allItemSummerLocalstorage, setAllItemSummerLocalstorage] = useState([])

  useEffect(() => {
    const getLocalStorageItems = getItemLocalStorage("compare")
    if (getLocalStorageItems) {
      getLocalStorageItems.map(item => {
        axios
          .get(`/schools/${item.id}?lang=${lang}`)
          .then(res => {
            setAllItemLocalstorage(prev => [...prev, res.data])
          })
          .catch()
      })
    }

    const getSummerLocalStorageItems = getItemLocalStorage("compare-summer")
    setAllItemSummerLocalstorage(getSummerLocalStorageItems)
  }, [])

  const handleRemoveItem = (itemId, index) => {
    let array = [...allItemLocalstorage]
    let resultArray = array.filter((_, i) => i !== index)
    setAllItemLocalstorage(resultArray)
    localStorage.setItem("compare", JSON.stringify(resultArray))
  }

  const handleRemoveItemSummer = (itemId, index) => {
    let array = [...allItemSummerLocalstorage]
    let resultArray = array.filter((_, i) => i !== index)
    setAllItemSummerLocalstorage(resultArray)
    localStorage.setItem("compare-summer", JSON.stringify(resultArray))
  }

  function resolveSchoolFees(item) {
    if (
      item.perTermMin !== item.perTermMax &&
      item.perTermMin != null &&
      item.perTermMax != null
    ) {
      return (
        <td>
          £{addSpaceNumberRegEx(item.perTermMin)}–{addSpaceNumberRegEx(item.perTermMax)}
        </td>
      )
    } else {
      return <td>£{addSpaceNumberRegEx(item.perTermMin || item.perTermMax)}</td>
    }
  }

  function resolveSummerSchoolFees(item) {
    if (
      item.weeklyFeeMin !== item.weeklyFeeMax &&
      item.weeklyFeeMin != null &&
      item.weeklyFeeMax != null
    ) {
      return (
        <td>
          £{addSpaceNumberRegEx(item.weeklyFeeMin)}–{addSpaceNumberRegEx(item.weeklyFeeMax)}
        </td>
      )
    } else {
      return (
        <td>£{addSpaceNumberRegEx(item.weeklyFeeMin || item.weeklyFeeMax)}</td>
      )
    }
  }

  function resolveSchoolDeposit(item) {
    if (
      item.depositMin !== item.depositMax &&
      item.depositMin != null &&
      item.depositMax != null
    ) {
      return (
        <td>
          £{addSpaceNumberRegEx(item.depositMin)}–{addSpaceNumberRegEx(item.depositMax)}
        </td>
      )
    } else {
      return <td>£{addSpaceNumberRegEx(item.depositMin || item.depositMax)}</td>
    }
  }

  allItemLocalstorage.sort((a, b) => a.name?.localeCompare(b.name))
  allItemSummerLocalstorage.sort((a, b) => a.name?.localeCompare(b.name))

  return (
    <div className="container">
      {allItemLocalstorage.length > 0 ? (
        <div className="f-jc compare-schools-content">
          {/* <div className="row"> */}
          <div className="compare-schools-wrapper col-12">
            <div className="compare-titles f-jb container">
              <div className="user-profile-title f-js">
                {t("main.userProfileMap.compareSchools")}
              </div>
              <div className="compare-location-wrapper">
                <div className="compare-location-link-wrapper">
                  <Link to={`/${lang}/profile/compare-location`}>
                    <div className="compare-location-link">
                      {t("main.userProfileMap.compareLocation")}
                    </div>
                  </Link>
                </div>
                <CompareIcon className="compare-location-icon" />
              </div>
            </div>

            <div className="table-wrapper">
              <table className="school-item-table">
                <thead>
                  <tr>
                    <th className="empty-section"></th>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map((item, index) => {
                        return (
                          <th>
                            {/* <Link to={`/${lang}/school/${item?.engName}`}> */}
                            <div className="school-comparison-item-wrapper">
                              <div className="selectIcon">
                                <Link to={`/${lang}/school/${item?.engName}`}>
                                  <img
                                    src={item.resolvedImageUrl}
                                    alt={item.resolvedImageUrl}
                                    className="school-comparison-item-image"
                                  />
                                </Link>
                                <DeleteIcon
                                  onClick={() => handleRemoveItem(item, index)}
                                  className="delete-icon-compare"
                                />
                              </div>
                              <div className="school-comparison-item-title">
                                {item.name}
                              </div>
                            </div>
                            {/* </Link> */}
                          </th>
                        )
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.typeOfSchool")}
                    </td>

                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return <td>{item.schoolTypesResolved}</td>
                      })}
                  </tr>
                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.ageRange")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <td>
                            {item.ageMin}–{item.ageMax} {t("school.years")}
                          </td>
                        )
                      })}
                  </tr>
                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.numberOfStudetns")}{" "}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <>
                            {item.numberOfStudents ? (
                              <td>{item.numberOfStudents}</td>
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>
                  <tr>
                    <td className="row-title ">
                      {t("school.keydata.boardersPercent")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <>
                            {item.boardersAtSchool ? (
                              <td>{item.boardersAtSchool}%</td>
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>
                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.internationalStudents")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <>
                            {item.internationalStudents ? (
                              <td>{item.internationalStudents}%</td>
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">{t("school.header.founded")}</td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return <td>{item.foundationYear}</td>
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("school.keydata.edProgrammes")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return <td>{item.educationalProgramsResolved}</td>
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("school.keydata.teachers")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <>
                            {item.teacherToStudentsRatio ? (
                              <td>1:{item.teacherToStudentsRatio}</td>
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("school.keydata.religion")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return <td>{item.religionResolved}</td>
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("school.fees.feesPerTerm")}
                    </td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <>
                            {item.perTermMin != null &&
                            item.perTermMin !== 0 ? (
                              resolveSchoolFees(item)
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">{t("school.fees.deposit")}</td>
                    {allItemLocalstorage &&
                      allItemLocalstorage.map(item => {
                        return (
                          <>
                            {item.depositMin != null ? (
                              resolveSchoolDeposit(item)
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>
                </tbody>
              </table>
            </div>
          {/* </div> */}
        
          </div>
        </div>
      ) : (
        <>
          {allItemSummerLocalstorage.length === 0 && (
            <div className="compare-schools-wrapper no-schools col-12">
              <div className="compare-titles f-jb container justify-content-center">
                <div className="user-profile-title f-js">
                  {t("main.userProfileMap.noSchoolsToCompare")}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {allItemSummerLocalstorage.length !== 0 && (
        <div className="f-jc compare-schools-content">
          <div className="compare-schools-wrapper col-12">
            <div className="compare-titles f-jb container">
              <div className="user-profile-title f-js">
                {t("main.userProfileMap.compareSummerSchools")}
              </div>
              <div className="compare-location-wrapper">
                <div className="compare-location-link-wrapper">
                  <Link to={`/${lang}/profile/compare-location`}>
                    <div className="compare-location-link">
                      {t("main.userProfileMap.compareLocation")}
                    </div>
                  </Link>
                </div>
                <CompareIcon className="compare-location-icon" />
              </div>
            </div>

            <div className="table-wrapper">
              <table className="school-item-table">
                <thead>
                  <tr>
                    <th className="empty-section"></th>
                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map((item, index) => {
                        return (
                          <th>
                            <div className="school-comparison-item-wrapper">
                              <div className="selectIcon">
                                <Link
                                  to={`/${lang}/summer-schools/${item?.engName}`}
                                >
                                  <img
                                    src={item.resolvedImageUrl}
                                    alt={item.resolvedImageUrl}
                                    className="school-comparison-item-image"
                                  />
                                </Link>
                                <DeleteIcon
                                  onClick={() =>
                                    handleRemoveItemSummer(item, index)
                                  }
                                  className="delete-icon-compare"
                                />
                              </div>
                              <div className="school-comparison-item-title">
                                {item.name}
                              </div>
                            </div>
                          </th>
                        )
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.typeOfSchool")}
                    </td>

                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map(item => {
                        return <td>{item.schoolTypesResolved}</td>
                      })}
                  </tr>
                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.ageRange")}
                    </td>
                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map(theSchool => {
                        return (
                          <td>
                            {theSchool.ageMin}–{theSchool.ageMax}{" "}
                            {t("school.years")}
                          </td>
                        )
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">{t("school.header.founded")}</td>
                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map(item => {
                        return <td>{item.foundationYear}</td>
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("school.keydata.edProgrammes")}
                    </td>
                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map(item => {
                        return <td>{item.educationalProgramsResolved}</td>
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.weeklyFeeMin")}
                    </td>
                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map(item => {
                        return (
                          <>
                            {item.weeklyFeeMin != null &&
                            item.weeklyFeeMin !== 0 ? (
                              resolveSummerSchoolFees(item)
                            ) : (
                              <td />
                            )}
                          </>
                        )
                      })}
                  </tr>

                  <tr>
                    <td className="row-title ">
                      {t("main.userProfileMap.studyCNubmer")}
                    </td>
                    {allItemSummerLocalstorage &&
                      allItemSummerLocalstorage.map(item => {
                        return (
                          <>
                            <td>{item.studyCentersNumber}</td>
                          </>
                        )
                      })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfileCompareSchoolsSection
