import * as React from "react"

// import "../../styles/userCompareSchools.css"
import UserProfileCompareSchoolsSection from "../../sections/user_profile_sections/UserProfileCompareSchoolsSection"
import UserProfileHeaderSection from "../../sections/user_profile_sections/UserProfileHeaderSection"

import { useCurrentUser } from "../../components/CurrentUserContext"

import AccessDeniedMessage from "../../components/AccessDeniedMessage"

import Layout from "../../components/Layout"

const UserCompareSchoolsPage = () => {
  const { currentUser, userLoading } = useCurrentUser()

  return (
    <Layout isSecondLayout={true}>
      {currentUser && currentUser.accountType === "user" ? (
        <div className="global-wrapper user-saved">
          <UserProfileHeaderSection state6="active" choice_status6="chosen" />
          <UserProfileCompareSchoolsSection />
        </div>
      ) : (
        <AccessDeniedMessage
          currentUser={currentUser}
          userLoading={userLoading}
        />
      )}
    </Layout>
  )
}

export default UserCompareSchoolsPage
